import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Container as CopyContainer } from '@components/block-library/copy';
import { MailingListForm } from '@components/block-library/mailing-list';
import {
  StyledFieldset,
  StyledLegend,
  StyledTextInput
} from '@components/form';

export const FooterContainer = styled.footer`
  color: ${props => props.theme.colors.grey.medium};
  background-color: ${props => props.theme.colors.grey.dark};
`;

const FlexContainer = styled.div`
  padding: 1rem 0;

  ${({ theme }) => theme.media.phone`
    justify-content: center;
  `}

  ${({ theme }) => theme.media.tablet`
    display: flex;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 0.5rem;
    justify-content: flex-start;
  `}

  > * {
    flex: 1 0;
    border-top: 1px solid ${props => props.theme.colors.grey.light};

    &:first-child {
      border-top: none;
    }

    ${({ theme }) => theme.media.tablet`
      border: none;
    `}
  }

  ${MailingListForm} {
    margin: 2rem 1rem;

    ${({ theme }) => theme.media.tablet`
      padding-right: 2rem;
    `}

    ${StyledFieldset} {
      border: none;
      padding: 0;
    }

    ${StyledLegend} {
      background-color: transparent;
      padding: 0;
    }

    ${StyledTextInput} {
      > span.input input[type] {
        color: ${props => props.theme.colors.grey.light};
        background-color: ${props => props.theme.colors.grey.dark};

        &:focus {
          outline: none;
          color: ${props => props.theme.colors.grey.dark};
          border-color: ${props => props.theme.colors.grey.light};
          background-color: ${props => props.theme.colors.grey.light};
        }
      }
    }
  }

  ${CopyContainer} {
    flex: 1;
    margin: 2rem 1rem;

    ${({ theme }) => theme.media.tablet`
      padding-right: 2rem;
    `}

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p.embedded-image {
      display: inline;
      margin-right: 2rem;
    }

    p:empty:last-child {
      display: none;
    }
  }
`;

const DonateCta = styled.nav`
  position: fixed;
  z-index: 20;
  right: -2rem;
  bottom: 50%;
  line-height: 1;
  transform: rotate(270deg);

  a {
    display: block;
    padding: 0.5rem 1rem;
    color: inherit;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.orange};

    &:hover {
      text-decoration: none;
    }
  }
`;

const Footer = ({ children, renderDonateCta = false }) => {
  return (
    <FooterContainer>
      {renderDonateCta && (
        <DonateCta>
          <Link to="/doneer?frequency=one-time&amount=10.00">Doneer</Link>
        </DonateCta>
      )}
      <FlexContainer>{children}</FlexContainer>
    </FooterContainer>
  );
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  renderDonateCta: PropTypes.bool.isRequired
};

export default Footer;
